// eslint-disable-next-line import/no-unresolved
import { ErrorPageContent } from '@react-ib-mf/style-guide-ui';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Project from './layouts/project';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='*' element={<ErrorPageContent />} />
        <Route path='/HSM' element={<Project />} />
      </Routes>
    </Router>
  );
}

export default App;
