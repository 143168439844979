/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

const newAxiosBff = axios.create({
  baseURL: process.env.CLAVEMAC_HSM_BFF_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
});
export const claveMacService = payload => newAxiosBff.post('/clave-mac', payload);
export const getClaveMacHabilitado = () => newAxiosBff.get('/clave-mac/habilitado');
