import { Button, Input } from '@chakra-ui/react';
import { ErrorComponent } from '@wow/error';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import forge from 'node-forge';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// import Panel from './styled';
import useChakraToast from '../hooks/useChakraToast';
import { claveMacService, getClaveMacHabilitado } from '../services/api';

function Project() {
  const [abonado, setAbonado] = useState<string>('');
  const [codigoBanco, setCodigoBanco] = useState<string>('');
  const [claveMac, setClaveMac] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [publicKey, setPublicKey] = useState<forge.pki.rsa.PublicKey | null>(null);
  const { ToastUtilities } = useChakraToast();
  const [isLoading, setIsloading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let publicKeyPath; 
    console.log('Entorno: ', process.env.ENVIROMENT);
    switch (process.env.ENVIROMENT) {
      case 'qa':
        publicKeyPath = require(`../assets/keys/publicKey_qa.pem`);
        break;
      case 'pre':
        publicKeyPath = require(`../assets/keys/publicKey_pre.pem`);
        break;
      case 'prod':
        publicKeyPath = require(`../assets/keys/publicKey_prod.pem`);
        break;
      case 'dev':
        publicKeyPath = require(`../assets/keys/publicKey_dev.pem`);
        break;
      default:
        publicKeyPath = 'error';
        break;
    }
    const loadedPublicKey = forge.pki.publicKeyFromPem(publicKeyPath);
    setPublicKey(loadedPublicKey); 
    getHasPermissions();
  }, []);

  useEffect(() => {
    if (abonado && codigoBanco && claveMac && otp) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [abonado, codigoBanco, claveMac, otp]);

  const getHasPermissions = async () => {
    await getClaveMacHabilitado().then(e => {
      setHasPermissions(e.data.estaHabilitado);
      setIsloading(false);
    });
  };

  const redirect = () => {
    navigate('/');
  };

  const clearFields = () => {
    setAbonado('');
    setCodigoBanco('');
    setClaveMac('');
    setOtp('');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSendingForm(true);
    const isAbonado = abonado.length === 7;
    const isBanco = codigoBanco.length === 3 && /^\d+$/.test(codigoBanco);
    const isMac = claveMac.length === 8;
    if (!publicKey) {
      throw new Error('Ocurrió un error al obtener la clave púplica.');
    }

    if (isAbonado && isBanco && isMac && otp) {
      
      const encrypted = publicKey.encrypt(claveMac, 'RSAES-PKCS1-V1_5');
      const encryptedSubmitMac = forge.util.encode64(encrypted);
      const macToCreate = {
        secret_key: `${abonado}${codigoBanco}`,
        secret_value: encryptedSubmitMac,
        otp,
      };
      await claveMacService(macToCreate)
        .then(() => {
          ToastUtilities.success({ id: 'toast-id', description: `El envío de la clave mac fue exitoso.` });
          clearFields();
        })
        .catch(() => {
          ToastUtilities.error({ id: 'toast-id', description: `Ocurrió un error durante el envío.` });
        })
        .finally(() => {
          setIsSendingForm(false);
        });
    } else {
      ToastUtilities.error({ id: 'toast-id', description: `Algún campo es inválido.` });
      setIsSendingForm(false);
    }
  };
  return (
    <>
      {isLoading && <LoadingScreen title='Estamos procesando tu información' description='Esto puede demorar unos instantes.' />}
      {!hasPermissions && !isLoading && (
        <ErrorComponent
          bodyText='Notamos que no tenés permisos para visualizar esta sección. Contactá a tu administrador para solicitar ayuda.'
          isRouter={false}
          linkText='Ir al inicio'
          callbackAction={redirect}
        />
      )}
      {hasPermissions && !isLoading && (
        <form onSubmit={handleSubmit}>
          <h1>Alta de clave mac en HSM</h1>
          <Input placeholder='Abonado:' type='text' value={abonado} onChange={e => setAbonado(e.target.value)} />
          <Input
            placeholder='El Código del banco debe tener 3 dígitos numéricos.'
            type='text'
            value={codigoBanco}
            onChange={e => setCodigoBanco(e.target.value)}
          />
          <Input placeholder='Clave MAC:' type='password' value={claveMac} onChange={e => setClaveMac(e.target.value)} />
          <Input placeholder='OTP' type='number' value={otp} onChange={e => setOtp(e.target.value)} />
          <Outlet />
          <Button variant='primary' type='submit' disabled={isSendingForm || !isComplete}>
            {' '}
            {isSendingForm ? 'Enviando' : 'Guardar Clave MAC'}
          </Button>
        </form>
      )}
    </>
  );
}

export default Project;
